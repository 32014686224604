<template>
  <v-footer
    v-if="inputFooterValue"
    id="core-footer"
    absolute
    height="82"
    style="border:0;padding-top: 0"
  >
    <div
      class="footer-items"
      style="padding-top: 0">
      <span
        v-for="link in links"
        :key="link.name"
      >
        <a
          :href="link.Link"
          class="tertiary--text footer-links">{{ link.name }}</a>
      </span>
    </div>
    <v-spacer/>
    <span class="font-weight-light copyright">
      &copy;
      {{ (new Date()).getFullYear() }},
      Created by Larry  <span style="font-size: 13px;color: #bbbbbb">&nbsp;&nbsp;  {{ version }}</span>
    </span>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    links: [
      { name: 'Files', Link: '/files' },
      { name: 'About', Link: '/about' }
    ]
  }),
  computed: {
    inputFooterValue: {
      get () {
        return this.$store.state.app.footer
      },
      set (val) {
        this.setFooter(val)
      }
    },
    version () {
      if (!this.$isEmpty(this.$store.state.app.signIn) && !this.$isEmpty(this.$store.state.app.signIn.serverversion)) {
        return this.$store.state.app.version + '-' + this.$store.state.app.signIn.serverversion
      } else {
        return this.$store.state.app.version
      }
    }
  }
}
</script>

<style>
#core-footer {
  z-index: 0;
}
</style>
