export default {
  // primary: '#4caf50',
  // secondary: '#4caf50',
  // tertiary: '#495057',
  // accent: '#82B1FF',
  // error: '#f55a4e',
  // info: '#00d3ee',
  // success: '#5cb860',
  // warning: '#ffa21a'

  primary: '#2196f3',
  secondary: '#03a9f4',
  tertiary: '#495057',
  accent: '#4caf50',
  error: '#f44336',
  warning: '#ffc107',
  info: '#009688',
  success: '#8bc34a',
  danger: '#ff5722'
}
