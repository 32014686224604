import PreviewImageLite from './PreviewImageLite.vue'

import Vue from 'vue'

const v = new Vue({
  render (createElement) {
    return createElement(PreviewImageLite)
  }
})
v.$mount()
document.body.appendChild(v.$el)

const previewImageLite = v.$children[0]

function preview (message) {
  previewImageLite.preview(message)
}

export default {
  preview
}
