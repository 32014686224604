<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="inputValue"
    app
    dark
    floating
    persistent
    mobile-break-point="991"
    width="260"
  >
    <v-img
      :src="image"
      :gradient="sidebarOverlayGradiant"
      height="100%"
    >
      <v-layout
        class="fill-height"
        tag="v-list"
        column
      >
        <v-list-tile avatar>
          <v-list-tile-avatar
            color="blue"
          >
            <v-img
              :src="logo"
              height="46"
              contain
            />
          </v-list-tile-avatar>
          <v-list-tile-title class="title">
            {{ system }}
          </v-list-tile-title>
        </v-list-tile>
        <v-divider/>
        <v-list-tile
          v-if="responsive"
        >
          <v-text-field
            class="purple-input search-input"
            label="Search..."
            color="purple"
          />
        </v-list-tile>
        <v-list-tile
          v-for="(link, i) in links"
          :key="i"
          :to="link.to"
          :active-class="color"
          avatar
          class="v-list-item"
        >
          <v-list-tile-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-title
            v-text="link.text"
          />
        </v-list-tile>
        <div v-if="isSuperAdmin">
          <v-list-tile
            v-for="(link, i) in adminLinks"
            :key="i+1"
            :to="link.to"
            :active-class="color"
            class="v-list-item"
          >
            <v-list-tile-action>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title
              v-text="link.text"
            />
          </v-list-tile>
        </div>
        <v-list-tile
          active-class="primary"
          class="v-list-item v-list__tile--buy"
          to="about"
        >
          <v-list-tile-action>
            <v-icon size="20">mdi-rhombus-medium</v-icon>
          </v-list-tile-action>
          <v-list-tile-title class="font-weight-light">
            关于
          </v-list-tile-title>
        </v-list-tile>
      </v-layout>
    </v-img>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {
  mapMutations,
  mapState
} from 'vuex'

export default {
  data: () => ({
    system: 'OTA管理系统',
    logo: './img/system.png',
    links: [
      {
        to: '/firmwares',
        icon: 'mdi-folder-multiple-outline',
        text: '固件管理'
      }
    ],
    adminLinks: [
      {
        to: '/members',
        icon: 'mdi-account-multiple',
        text: '成员管理'
      },
      {
        to: '/projects',
        icon: 'mdi-view-comfy',
        text: '项目管理'
      }
    ],
    responsive: false
  }),
  computed: {
    ...mapState('app', ['image', 'color']),
    inputValue: {
      get () {
        return this.$store.state.app.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    },
    items () {
      return this.$t('Layout.View.items')
    },
    sidebarOverlayGradiant () {
      return `${this.$store.state.app.sidebarBackgroundColor}, ${this.$store.state.app.sidebarBackgroundColor}`
    },
    isSuperAdmin () {
      // console.log('this.$store.state.app.signIn: ', this.$store.state.app.signIn)
      if (this.$store.state.app.signIn == null) {
        return false
      } else if (this.$store.state.app.signIn.username === 'superadmin') {
        return true
      }
      return false
    }
  },
  created () {
    // console.log('Drawer.vue created()')
    this.$store.state.app.signIn = this.$session.get('signIn')
    this.$i18n.locale = 'cn'
    let color = localStorage.getItem('color')
    if (color != null) {
      this.$store.state.app.color = color
    }
    let image = localStorage.getItem('image')
    if (image != null) {
      this.$store.state.app.image = image
    }
  },
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    }
  }
}
</script>

<style lang="scss">
  #app-drawer {
    .v-list__tile {
      border-radius: 4px;

      &--buy {
        margin-top: auto;
        margin-bottom: 17px;
      }
    }

    .v-image__image--contain {
      top: 9px;
      height: 60%;
    }

    .search-input {
      margin-bottom: 30px !important;
      padding-left: 15px;
      padding-right: 15px;
    }

    div.v-responsive.v-image > div.v-responsive__content {
      overflow-y: auto;
    }
  }
</style>
