<template>
  <vue-draggable-resizable
    ref="vdr"
    :active="active"
    :draggable="draggable"
    :resizable="resizable"
    :w="parseInt(width)"
    :h="parseInt(height)"
    :x="parseInt(x)"
    :y="parseInt(y)"
    :min-width="parseInt(minWidth)"
    :min-height="parseInt(minHeight)"
    :parent="true"
    :class-name-handle="myHandleClass"
    active.sync="active"
    class="dragging1"
    @resizing="onResize"
    @dragging="onDrag"
    @activated="onActivated"
    @deactivated="onDeactivated"
  >
    <v-card
      :class="`elevation-${elevation}`"
      :color="color"
      style="padding: 0px!important;"
      width="100%"
      height="100%"
      class="pa-4"
      dark
      @click.prevent.stop="onclick"
    >
      <div
        :style="{'text-align': 'center'}">
        <v-icon
          size="20"
          class="icon_center"
        >
          {{ icon }}
        </v-icon>
        <!--        <p-->
        <!--          class="category white&#45;&#45;text font-weight-light"-->
        <!--          v-text="title"-->
        <!--        />-->
      </div>
    </v-card>
  </vue-draggable-resizable>
</template>

<script>
import Card from './Card'

export default {

  props: {
    ...Card.props,
    id: {
      type: [Number, String],
      default: 'id'
    },
    title: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    myHandleClass: {
      type: String,
      default: undefined
    },
    active: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    resizable: {
      type: Boolean,
      default: true
    },
    x: {
      type: [Number, String],
      default: 0,
      validator: function validator (val) {
        return val >= 0
      }
    },
    y: {
      type: [Number, String],
      default: 0,
      validator: function validator (val) {
        return val >= 0
      }
    },
    width: {
      type: [Number, String],
      default: 200,
      validator: function validator (val) {
        return val > 0
      }
    },
    height: {
      type: [Number, String],
      default: 200,
      validator: function validator (val) {
        return val > 0
      }
    },
    minWidth: {
      type: [Number, String],
      default: 100,
      validator: function validator (val) {
        return val > 0
      }
    },
    minHeight: {
      type: [Number, String],
      default: 100,
      validator: function validator (val) {
        return val > 0
      }
    },
    resizing: {
      type: Function,
      default: null
    },
    dragging: {
      type: Function,
      default: null
    },
    click: {
      type: Function,
      default: null
    },
    activated: {
      type: Function,
      default: null
    },
    deactivated: {
      type: Function,
      default: null
    }
  },
  computed: {
  },
  methods: {
    onResize: function (x, y, width, height) {
      this.$emit('resizing', this.id, x, y, width, height)
    },
    onDrag: function (x, y) {
      this.$emit('dragging', this.id, x, y)
    },
    onclick: function () {
      this.$emit('click')
    },
    onActivated: function () {
      this.$emit('activated')
    },
    onDeactivated: function () {
      this.$emit('deactivated')
    },
    renew: function () {
      this.$refs.vdr.$mount()
    }
  }
}
</script>

<style scoped>
  .dragging1 {
    border: 0;
    color: #000;
  }
  .icon_center {
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
</style>
