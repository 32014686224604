<template>
  <v-toolbar
    v-if="inputToolbarValue"
    id="core-toolbar"
    flat
    prominent
    style="background: #eee;"
  >
    <div class="v-toolbar-title">
      <v-toolbar-title
        class="tertiary--text font-weight-light"
      >
        <v-btn
          class="default v-btn--simple"
          dark
          icon
          @click.stop="onClickBtn"
        >
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
        {{ title }}
      </v-toolbar-title>
    </div>

    <v-spacer />
    <v-toolbar-items>
      <v-flex
        align-center
        layout
        py-2
      >
        <v-menu
          bottom
          left
          content-class="dropdown-menu"
          offset-y
          transition="slide-y-transition">
          <router-link
            v-ripple
            slot="activator"
            class="toolbar-items"
            to=""
          >
            <v-badge
              color="error"
              overlap
            >

              <v-icon color="tertiary">mdi-account</v-icon>
              <span class="text-gray text-uppercase"> {{ UserName }}</span>
            </v-badge>
          </router-link>
          <v-card>
            <v-list dense>
              <v-list-tile
                v-for="userMessage in userMenus"
                :key="userMessage.text"
                @click="onClick(userMessage.text)"
              >
                <v-icon left>{{ userMessage.icon }}</v-icon>
                <v-list-tile-title
                  v-text="userMessage.text"
                />
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
      </v-flex>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>

import {
  mapMutations,
  mapState
} from 'vuex'

export default {
  data: () => ({
    username: '',
    userMenus: [
      {
        to: '/userInfo',
        icon: 'mdi-account-edit',
        text: '用户信息'
      },
      {
        to: '/login',
        icon: 'mdi-exit-to-app',
        text: '注销'
      }
    ],
    title: null,
    responsive: false,
    responsiveInput: false
  }),
  computed: {
    ...mapState('app', ['image', 'color']),
    inputToolbarValue: {
      get () {
        return this.$store.state.app.toolbar
      },
      set (val) {
        this.setToolbar(val)
      }
    },
    UserName () {
      if (this.$store.state.app.signIn === undefined) {
        if (this.$session.get('signIn') === undefined) {
          return ''
        } else {
          return this.$session.get('signIn').username
        }
      } else {
        return this.$store.state.app.signIn.username
      }
    }
  },
  watch: {
    '$route' (val) {
      this.title = val.name
    }
  },
  created () {
    if (this.$store.state.app.signIn === undefined) {
      if (this.$session.get('signIn') === undefined) {
        this.username = ''
      } else {
        this.username = this.$session.get('signIn').username
      }
    } else {
      this.username = this.$store.state.app.signIn.username
    }
  },
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClickBtn () {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    onClick (userMessage) {
      for (const userMenu of this.userMenus) {
        if (userMessage === userMenu.text) {
          if (this.$route.path !== userMenu.to) {
            this.$router.push(userMenu.to)
          }
          return
        }
      }
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
        this.responsiveInput = false
      } else {
        this.responsive = false
        this.responsiveInput = true
      }
    }
  }
}
</script>

<style>
  #core-toolbar a {
    text-decoration: none;
  }
</style>
