<template>
  <v-menu
    :close-on-content-click="false"
    bottom
    left
    min-width="300"
    max-width="300"
    nudge-left="12"
    offset-x
    transition="slide-y-transition"
  >
    <v-btn
      slot="activator"
      class="elevation-0"
      color="grey"
      dark
      fab
      fixed
      style="top: 96px;"
      top
    >
      <v-icon>mdi-settings</v-icon>
    </v-btn>
    <v-card>
      <v-container grid-list-xl>
        <v-layout wrap>
          <v-flex xs12>
            <div class="text-xs-center body-2 text-uppercase sidebar-filter">颜色</div>

            <v-layout justify-center>
              <v-avatar
                v-for="c in colors"
                :key="c"
                :class="[c === color ? 'color-active color-' + c: 'color-' + c]"
                size="23"

                @click="setColor(c)"
              />
            </v-layout>
            <v-divider class="mt-3"/>
          </v-flex>
          <v-flex
            xs12
          >
            <div class="text-xs-center body-2 text-uppercase sidebar-filter">图片</div>
          </v-flex>
          <v-flex
            v-for="img in images"
            :key="img"
            xs3
          >
            <v-img
              :class="[image === img ? 'image-active' : '']"
              :src="img"
              height="120"
              @click.native="setImage(img)"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
// Utilities
// import {
//   mapMutations,
//   mapState
// } from 'vuex'

export default {
  data: () => ({
    colors: [
      'primary',
      'info',
      'success',
      'warning',
      'danger',
      'secondary',
      'tertiary',
      'accent',
      'error'
    ],
    images: [
      './img/sidebar/sidebar-11.jpg',
      './img/sidebar/sidebar-22.jpg',
      './img/sidebar/sidebar-33.jpg',
      './img/sidebar/sidebar-44.jpg',
      './img/sidebar/sidebar-1.jpg',
      './img/sidebar/sidebar-2.jpg',
      './img/sidebar/sidebar-3.jpg',
      './img/sidebar/sidebar-4.jpg',
      './img/sidebar/sidebar-1-2.jpg',
      './img/sidebar/sidebar-2-2.jpg',
      './img/sidebar/sidebar-3-2.jpg',
      './img/sidebar/sidebar-4-2.jpg'
    ]
  }),

  computed: {
    // ...mapState('app', ['image', 'color'])
    image () {
      return this.$store.state.app.image
    },
    color () {
      return this.$store.state.app.color
    }
  },

  methods: {
    // ...mapMutations('app', ['setImage', 'setColor'])
    setImage (image) {
      this.$store.state.app.image = image
      localStorage.setItem('image', image)
    },
    setColor (color) {
      this.$store.state.app.color = color
      localStorage.setItem('color', color)
    }
  }
}
</script>

<style lang="scss">
  .v-avatar,
  .v-responsive {
    cursor: pointer;
  }
</style>
