/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/login',
    name: 'Login',
    view: 'Login'
  },
  {
    path: '/projects',
    name: '项目管理',
    view: 'ProjectManagement'
  },
  {
    path: '/firmwares',
    name: '固件管理',
    view: 'FirmwareManagement'
  },
  {
    path: '/members',
    name: '成员管理',
    view: 'MemberManagement'
  },
  {
    path: '/userInfo',
    name: '个人信息',
    view: 'UserInfo'
  },
  {
    path: '/about',
    name: 'About',
    view: 'About'
  },
  {
    path: '/error404',
    name: 'Error404',
    view: 'Error404'
  }
]
