export default {
  drawer: null,
  toolbar: true,
  footer: true,
  color: 'success',
  image: './img/sidebar/sidebar-11.jpg',
  // './img/sidebar/sidebar-11.jpg',
  // './img/sidebar/sidebar-22.jpg',
  // './img/sidebar/sidebar-33.jpg',
  // './img/sidebar/sidebar-44.jpg',
  // './img/sidebar/sidebar-1.jpg',
  // './img/sidebar/sidebar-2.jpg',
  // './img/sidebar/sidebar-3.jpg',
  // './img/sidebar/sidebar-4.jpg',
  // './img/sidebar/sidebar-1-2.jpg',
  // './img/sidebar/sidebar-2-2.jpg',
  // './img/sidebar/sidebar-3-2.jpg',
  // './img/sidebar/sidebar-4-2.jpg'
  sidebarBackgroundColor: 'rgba(27, 27, 27, 0.74)',
  // url: '',
  // url: 'http://127.0.0.1:9002/api/v1',
  // url: 'http://ota.elinkthings.com/api/v1',
  url: window.location.protocol + '//' + window.location.host + '/api/v1',
  signIn: {},
  version: 'Ver 0.9.1(20201109)'
}
