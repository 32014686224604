<template>
  <!--<v-snackbar-->
  <!--  id="snackbar"-->
  <!--  v-model="open"-->
  <!--  :class="`white&#45;&#45;text`"-->
  <!--  :timeout="timeout"-->
  <!--  :color="color"-->
  <!--  top-->
  <!--  auto-height>-->
  <!--  <v-icon color="white">{{ icon }}</v-icon>-->
  <!--  {{ mes }}-->
  <!--</v-snackbar>-->
  <v-snackbar
    :color="color"
    :timeout="timeout"
    v-model="open"
    top
    auto-height
    dark
  >
    <v-icon
      color="white"
      class="mr-3"
    >
      {{ icon }}
    </v-icon>
    <div>{{ mes }}</div>
    <v-icon
      size="16"
      @click="open = false"
    >
      mdi-close
    </v-icon>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  components: {},
  props: {
    timeout: {
      type: [Number, String],
      default: 6000
    }
  },
  data () {
    return {
      icon: '',
      color: 'red',
      open: false,
      mes: ''
    }
  },
  created () {},
  methods: {
    info (mes) {
      // console.log('info(): ', mes)
      this.icon = 'mdi-information'
      this.color = 'info'
      this.open = true
      this.mes = mes
    },
    error (mes) {
      // console.log('error(): ', mes)
      this.icon = 'mdi-close-circle'
      this.color = 'error'
      this.open = true
      this.mes = mes
    },
    warning (mes) {
      // console.log('warning(): ', mes)
      this.icon = 'mdi-alert-circle'
      this.color = 'warning'
      this.open = true
      this.mes = mes
    },
    success (mes) {
      // console.log('success(): ', mes)
      this.icon = 'mdi-check-circle'
      this.color = 'success'
      this.open = true
      this.mes = mes
    }
  }
}
</script>
