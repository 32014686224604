import Snackbar from './Snackbar.vue'

import Vue from 'vue'

const v = new Vue({
  render (createElement) {
    return createElement(Snackbar)
  }
})
v.$mount()
document.body.appendChild(v.$el)

const snackbar = v.$children[0]

function info (message) {
  snackbar.info(message)
}

function error (message) {
  snackbar.error(message)
}

function warning (message) {
  snackbar.warning(message)
}

function success (message) {
  snackbar.success(message)
}
export default {
  info,
  success,
  warning,
  error
}
