exports.install = function (Vue, options) {
  Vue.prototype.$isEmpty = function (obj) {
    if (typeof obj === 'undefined' || obj == null || obj === '') {
      return true
    } else {
      return false
    }
  }

  Vue.prototype.$getDateString = function (timestamp) {
    // return '2019-01-01'
    let time = new Date()
    if (timestamp) {
      time = new Date(timestamp)
    }
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let day = time.getDate()
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return year + '-' + month + '-' + day
  }

  Vue.prototype.$getDateTimeString = function (timestamp) {
    if (timestamp == null) {
      return '-'
    }
    // return '2019-01-01 12:34:56'
    let time = new Date()
    if (timestamp) {
      time = new Date(timestamp)
    }
    let year = time.getFullYear()
    let month = time.getMonth() + 1
    let day = time.getDate()
    let hour = time.getHours()
    let minute = time.getMinutes()
    let second = time.getSeconds()
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    if (hour < 10) {
      hour = '0' + hour
    }
    if (minute < 10) {
      minute = '0' + minute
    }
    if (second < 10) {
      second = '0' + second
    }
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
  }

  Vue.prototype.$formatFileSize = function (size) {
    if (size < 1024) {
      return size + 'B'
    }
    if (size < 1024 * 1024) {
      return (size / 1024).toFixed(2) + 'KB'
    }
    if (size < 1024 * 1024 * 1024) {
      return (size / 1024 / 1024).toFixed(2) + 'MB'
    }
    return (size / 1024 / 1024 / 1024).toFixed(2) + 'GB'
  }

  Vue.prototype.$authHeaders = function () {
    if (this.$isEmpty(this.$store.state.app.signIn)) {
      this.$reLogin()
    } else {
      return {
        headers: {
          Authorization: this.$store.state.app.signIn.token
        }
      }
    }
  }

  Vue.prototype.$httpCatch = function (error) {
    // console.error('$httpCatch(): ', error)
    if (this.$isEmpty(error.response)) {
      this.$snackbar.error(error)
    } else {
      if (error.response.status === 401) {
        this.$reLogin()
      } else {
        console.error('error.response.status', error.response.status)
      }
    }
  }

  Vue.prototype.$checkAuth = function () {
    // this.$http
    //   .get(this.$store.state.app.url + '/admin/ctrl/AuthToken', this.$authHeaders())
    //   .then(result => {
    //     // console.log(result.data)
    //   })
    //   .catch(error => {
    //     this.$httpCatch(error)
    //   })
  }

  Vue.prototype.$reLogin = function () {
    alert('登录超时需要重新登录！')
    // this.$router.push({ path: '/login' })
    // window.location.href = '/login'
    window.open('/login', '_self')
  }

  // Vue.prototype.$sendMessage = function (deviceId, message) {
  //   if (this.$isEmpty(deviceId) || this.$isEmpty(message)) {
  //     this.$snackbar.info('参数为空！')
  //     return
  //   }
  //   let formData = new FormData()
  //   formData.append('deviceId', deviceId)
  //   formData.append('message', message)
  //   this.$http.post(this.$store.state.app.url + ' /mediaSystem/message/messageSend', formData, this.$authHeaders())
  //     .then(result => {
  //       let res = result.data
  //       if (res.code === 0) {
  //         this.$snackbar.success(res.message)
  //       } else {
  //         this.$snackbar.error(res.message)
  //       }
  //     }).catch(error => {
  //       this.$httpCatch(error)
  //     })
  // }
  //
  // Vue.prototype.$sendGroupMessage = function (groupId, message) {
  //   if (this.$isEmpty(groupId) || this.$isEmpty(message)) {
  //     this.$snackbar.info('参数为空！')
  //     return
  //   }
  //   let formData = new FormData()
  //   formData.append('deviceId', groupId)
  //   formData.append('message', message)
  //   this.$http.post(this.$store.state.app.url + ' /mediaSystem/message/groupMessageSend', formData, this.$authHeaders())
  //     .then(result => {
  //       let res = result.data
  //       if (res.code === 0) {
  //         this.$snackbar.success(res.message)
  //       } else {
  //         this.$snackbar.error(res.message)
  //       }
  //     }).catch(error => {
  //       this.$httpCatch(error)
  //     })
  // }
}
