<template>
  <div
    v-show="show"
    class="dialog-mask"
    @click="show = false"
  >
    <div
      class="img_wrap">
      <v-img
        :src="img"
        style="border: 1px solid #ccc;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewImageLite',
  props: {},
  data () {
    return {
      show: false,
      img: ''
    }
  },
  methods: {
    preview (img) {
      // console.log('preview(): ', img)
      this.img = img
      this.show = true
    }
  }
}
</script>

<style scoped lang="scss">
  .img_wrap{
    width: 400px;
    height: 300px;
    display: table-cell; //主要是这个属性
    vertical-align: middle;
    text-align: center;
  }
  .dialog-mask {
    background-color: #000000d0;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: table;
    animation-duration: 0.1s;
  }
</style>
